import React, { useState } from "react";
import "./ButtonsBarDisconnected.scss";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { updateAuth } from "Redux/Actions/authActions";
import CarouselHeader from "../CarouselHeader/CarouselHeader";
import { useTranslation } from "react-i18next";
import HeaderUserLogin from "../HeaderTop/HeaderUserLogin/HeaderUserLogin";
import ModalConnexion from "Components/Modal/ModalConnexion/ModalConnexion";
import { authenticationRedirection } from "Services/redirections";
import Modal from "Components/Modal/Modal";
import CompteUniversite from "Components/Modal/ModalCreationCompte/ModalCreationCompte";

const ButtonsBarDisconnected = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCreationCompte, setVisibleModalCreationCompte] =
    useState(false);
  const [authentication, setAuthentication] = useState(false);
  const [openConnexionInterface, setOpenConnexionInterface] = useState(false);
  const { t } = useTranslation("common");

  return (
    <div
      className="buttonsBarDisconnected"
      onClick={() => {
        if (openConnexionInterface) setOpenConnexionInterface(false);
      }}
    >
      <div className="buttonsBarDisconnected__carousel">
        <CarouselHeader />
      </div>
      <div className="buttonsBarDisconnected__On">
        {/* <div className="buttons_bar"> */}
        <h2 className="accueilTitle">
          {/* Bienvenue sur votre espace Alumni Paris 1 */}
          {t("presentation.message_bienvenue")}
        </h2>
        <div
          className="buttonsBarDisconnected__On__button"
          // Evenement desktop
          onMouseEnter={() => setOpenConnexionInterface(true)}
          onMouseLeave={() => setOpenConnexionInterface(false)}
          // Evenement mobile
          onTouchStart={() => setOpenConnexionInterface(true)}
          onTouchEnd={() => setOpenConnexionInterface(false)}
          onClick={() => setOpenConnexionInterface(true)}
        >
          <button className="accueilButton">
            {" "}
            {t("presentation.rejoindre_btn")}{" "}
          </button>
          {openConnexionInterface && (
            <div className="connexionInterface">
              <ul>
                <li
                  onClick={() => {
                    if (!authenticationRedirection())
                      setVisibleModal(!visibleModal);
                  }}
                  onTouchStart={() => {
                    if (!authenticationRedirection())
                      setVisibleModal(!visibleModal);
                  }}
                >
                  {t("header.connexion")}
                </li>
                <li
                  onClick={() =>
                    setVisibleModalCreationCompte(!visibleModalCreationCompte)
                  }
                  onTouchStart={() =>
                    setVisibleModalCreationCompte(!visibleModalCreationCompte)
                  }
                >
                  {t("header.compte_creation")}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        />
      )}
      {authentication && (
        <ModalConnexion
          loginVisible={authentication}
          setLoginVisible={setAuthentication}
        />
      )}
      {visibleModalCreationCompte && (
        <Modal
          visible={visibleModalCreationCompte}
          setVisible={setVisibleModalCreationCompte}
          header={t("header.compte_creation")}
          width={"50vw"}
          blockScroll={true}
        >
          <CompteUniversite
            visibleModal={visibleModalCreationCompte}
            setVisibleModal={setVisibleModalCreationCompte}
          ></CompteUniversite>
        </Modal>
      )}
    </div>
  );
};

ButtonsBarDisconnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonsBarDisconnected);
